<template>
  <div class="containerFlexColumn">
    <ul class="wHP">
      <li v-for="nav in navList" :key="nav.id" class="nav" @click="link(nav.path, nav.isFrame)">
        <!--        <i :class="nav.icon"></i>-->
        <span class="iconfont fs30">{{ nav.icon | unescapeIcon }}</span>
        <span>{{ nav.menuName }}</span>
      </li>
    </ul>
    <div class="marginTopAuto marginBottom10">
      <el-popover v-model="visible" placement="right" width="250" trigger="manual">
        <div class="h300 flex directionColumn">
          <div class="hFP flex">
            <div class="flex" style="width: 25%">
              <!--              <div class="h50" @click="openDialog">-->
              <div class="h50" @click="openDialog(4)">
                <el-avatar class="pointer" :size="50" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
              </div>
            </div>
            <div class="flex1">
              <div class="flex" style="align-items: center">
                <div class="fs20 blue pointer" @click="openDialog(1)">{{ user.nickName }}</div>
                <i class="el-icon-lock marginLeftAuto pointer" @click="openDialog(2)"></i>
              </div>
              <div class="blue pointer" @click="openDialog(3)">{{ user.phonenumber }}</div>
              <div>超级管理员</div>
            </div>
          </div>
          <div class="hFP userMenu">
            <el-menu default-active="2" class="el-menu-vertical-demo hFP" @open="handleOpen" @close="handleClose">
              <el-menu-item index="1" disabled>
                <span slot="title">消息中心</span>
              </el-menu-item>
              <el-menu-item index="2" @click="recharge">
                <span slot="title">充值中心</span>
              </el-menu-item>
              <el-menu-item index="3" disabled>
                <span slot="title">帮助中心</span>
              </el-menu-item>
              <el-menu-item index="4" @click="quitLogin">
                <span slot="title">退出登陆</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <div slot="reference" class="pointer" @click="visible = !visible">
          <el-avatar icon="el-icon-user-solid" />
        </div>
      </el-popover>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :before-close="handleDialogClose">
      <userInfoSetting v-if="dialogVisible" :type="setType" @closedDialog="closedDialog" />
    </el-dialog>
  </div>
</template>

<script>
import lodash from "lodash";
import store from "@/store";
import userInfoSetting from "@/views/login/userInfoSetting.vue";
import { removeToken } from "@/utils/auth";
export default {
  components: {
    userInfoSetting,
  },
  filters: {
    unescapeIcon(icon) {
      return unescape(icon.replace(/&#x/g, "%u").replace(/;/g, ""));
    },
  },
  data() {
    return {
      navList: "",
      order: ["1"],
      dialogVisible: false,
      user: "",
      title: "",
      setType: 0,
      visible: false,
    };
  },
  // name: "nav",
  mounted: function () {
    this.showNav();
    this.getUserInfo();
  },
  methods: {
    quitLogin() {
      this.$confirm('确认退出登陆？')
        .then((_) => {
          removeToken();
          window.location.reload();
        })
        .catch((_) => {});
    },
    recharge(){
      // const width = 800;
      // const height = 600;
      // const left = (window.screen.width / 2) - (width / 2);
      // const top = (window.screen.height / 2) - (height / 2);
      // window.open(
      //   'http://h5.open.maiyatian.com/payment/rechargeForMerchant/?merchant_id=1454&sign=0A9CDEF1AB194EEC584344759FF670B5',
      //   '_blank',
      //   `width=${width},height=${height},top=${top},left=${left}`
      // );
      window.open('http://h5.open.maiyatian.com/payment/rechargeForMerchant/?merchant_id=1454&sign=0A9CDEF1AB194EEC584344759FF670B5', '_blank')
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    closedDialog() {
      this.dialogVisible = false;
      this.getUserInfo();
    },
    openDialog(setType) {
      this.setType = setType;
      if (setType === 1) {
        this.title = "账号信息";
      } else if (setType === 2) {
        this.title = "修改密码";
      } else if (setType === 3) {
        this.title = "变更手机";
      } else if (setType === 4) {
        this.title = "更换头像";
      }
      this.dialogVisible = true;
    },
    getUserInfo() {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.user = userInfo;
    },
    handleDialogClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    showNav: function () {
      const nav = JSON.parse(JSON.stringify(store.getters.navs));
      if (nav == null) {
        nav = JSON.parse(sessionStorage.getItem("nav"));
      }
      let list = lodash
        .chain(nav)
        .filter((p) => {
          return p.parentId == 0;
        })
        .orderBy("orderNum", "asc")
        .value();
      list.forEach((o) => {
        o["children"] = lodash
          .chain(store.getters.navs)
          .filter((p) => {
            return p.parentId == o.id;
          })
          .orderBy("orderNum", "asc")
          .value();
      });
      this.navList = nav;
    },
    link: function (url, isFrame) {
      if (isFrame === "0") {
        window.open(url, '_blank')
      } else if (this.$route.path !== url) {
        this.$router.push({ path: url });
      }
    },
  },
};
</script>

<style lang="scss">
.userMenu {
  .el-menu-item,
  .el-submenu__title {
    height: 40px;
    line-height: 40px;
  }
}
</style>

<style lang="scss" scoped>
div,
ul {
  border-right: 0;
  /*background-color: rgb(50, 64, 78);*/
}

.containerFlexColumn {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 0;
  border: 1px solid rgb(222, 222, 222);
  display: flex;
  flex-direction: column;
  align-items: center;
  .el-menu-item {
    height: 40px;
    line-height: 40px;
  }
}

.nav {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 5px 0;

  & > i {
    font-size: 35px;
  }

  &:hover {
    background-color: #5f9fd9;
    cursor: pointer;
  }
}
</style>
